import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from "../../Utils/NetUtil";
import { Form } from '@ant-design/compatible';
import { ColumnProps } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import md5 from "md5"
import { message, Typography, Divider, Table, Button } from 'antd';
import CommDialog from '../../Utils/CommDialog';
import EnumUtil from '../../Utils/EnumUtil';
import AddChannelModal from './AddChannelModal';
import ChangeChannelModal from './ChangeChannelModal'

interface IProps {

}

interface IState {
    addChannelFormVisible: boolean,
    changeChannelFormData: any,
    LogsList: Array<any>
}


export default class ChannelListView extends React.Component<IProps, IState> {

    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            addChannelFormVisible: false,
            changeChannelFormData: null,
            LogsList: [],
        }
    }

    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //取消
    private handleCancel() {
        this.setState({
            addChannelFormVisible: false,
            changeChannelFormData: null,
        })
    }

    //处理弹出框提交完成关闭弹框  操作
    private handleCreate() {
        this.handleCancel();
        this.NetRefreshPanel();
    }

    NetRefreshPanel() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token),
        };

        NetUtil.Post(NetUtil.BaseUrl + "channel/list", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];
                let trades = new Array<{ key: number, value: string }>()

                if (msg.list) {
                    for (let index = 0; index < msg.list.length; index++) {
                        const element = msg.list[index];
                        data.push(element)

                        trades.push({ key: element.id, value: element.name })
                    }
                    StorageValue.Channel = trades.sort(
                        (a: any, b: any) => {
                            return a.key > b.key ? 1 : -1
                        })
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.id > b.id ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    private Disabled(record: any) {
        let text = record.enabled ? '禁用' : '解禁'
        let name = ' [' + EnumUtil.ChannelTypes.get(record.type) + "-" + record.name + '](ID:' + record.id + ')';
        CommDialog.showConfirm(text + "确认", "是否" + text + name, () => {
            let tm = Math.floor(new Date().valueOf() / 1000)
            let postData = {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(record.id + (!record.enabled) + tm + StorageValue.userName + StorageValue.Token),
                disabled: !record.enabled,
                change: record.id
            };

            NetUtil.Post(NetUtil.BaseUrl + "channel/disabled", postData,
                (msg: any) => {
                    let Result = msg.result
                    if (Result != 0) {
                        message.error(msg.msg);
                        return;
                    }

                    message.info(text + name + "成功！");
                    this.NetRefreshPanel();
                },
                (err: any) => {
                    throw (err)
                })
        });
    }

    private Change(record: any) {
        this.setState({
            changeChannelFormData: record,
        })
    }

    private Delete(record: any) {
        let name = ' [' + EnumUtil.ChannelTypes.get(record.type) + "-" + record.name + '](ID:' + record.id + ')';
        CommDialog.showConfirm("删除确认", "是否删除" + name, () => {
            let tm = Math.floor(new Date().valueOf() / 1000)
            let postData = {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(record.id + tm + StorageValue.userName + StorageValue.Token),
                change: record.id
            };

            NetUtil.Post(NetUtil.BaseUrl + "channel/del", postData,
                (msg: any) => {
                    let Result = msg.result
                    if (Result != 0) {
                        message.error(msg.msg);
                        return;
                    }

                    message.info("删除" + name + "成功！");
                    this.NetRefreshPanel();
                },
                (err: any) => {
                    throw (err)
                })
        });
    }

    render() {
        this.columns = [
            { align: "center", title: "ID", dataIndex: "id", key: "id", fixed: 'left' },
            { align: "center", title: "通道名", dataIndex: "name", key: "name" },
            {
                align: "center", title: "通道类型", dataIndex: "type", key: "type", render: (text, record) => {
                    return <div>{EnumUtil.ChannelTypes.get(text)}</div>
                }
            },
            {
                align: "center", title: "支付方式", dataIndex: "trade", key: "trade", render: (text, record) => {
                    return <div>{EnumUtil.ChannelTrade.get(text)}</div>
                }
            },
            { align: "center", title: "手续费", dataIndex: "fee", key: "fee" },
            { align: "center", title: "每日限额", dataIndex: "limit", key: "limit" },
            { align: "center", title: "当日充值", dataIndex: "use", key: "use" },
            {
                align: "center", title: "操作", dataIndex: "operation", key: "operation", render: (text, record) => {
                    if (record.enabled) {
                        return (<div>
                            <a href="javascript:;" onClick={() => this.Change(record)} >修改</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Disabled(record)} >禁用</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                        </div>)
                    } else {
                        return (<div>
                            <a href="javascript:;" onClick={() => this.Change(record)} >修改</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Disabled(record)} style={{color:"red"}} >解禁</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                        </div>)
                    }

                }
            },
        ]

        return (
            <div>
                <Form layout='inline'>
                    <Form.Item >
                        <Button
                            icon={<PlusOutlined />}
                            type="primary"
                            onClick={() => { this.setState({ addChannelFormVisible: true }) }}
                        >
                            添加通道
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />
                {/* //添加通道 */}
                <AddChannelModal
                    visible={this.state.addChannelFormVisible}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                {/* //修改通道 */}
                <ChangeChannelModal
                    data={this.state.changeChannelFormData}
                    visible={this.state.changeChannelFormData != null}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                <Table
                    pagination={{ pageSize: 100 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />

            </div >
        );
    }
}