import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, Button, Select } from 'antd';
import NetUtil from "../../Utils/NetUtil";
import StorageValue from '../../Utils/StorageValue';
import md5 from "md5"
const { Option } = Select;

interface IProps {
    visible: boolean
    onCancel: Function
    onSendApplySuccess: Function
}

interface IState {
    userId: string,
    nickname: string,
    pwd: string,
    secret: string,
    type: number,
    channel: string[],
    ips: string,
}

export default class AddUserModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            userId: "",
            nickname: "",
            pwd: "",
            secret: "",
            type: 3,
            channel: [],
            ips: "",
        }
    }

    setUserId(value: string) {
        this.setState({
            userId: value
        })
    }

    setNickName(value: string) {
        this.setState({
            nickname: value
        })
    }

    setPwd(value: string) {
        this.setState({
            pwd: value
        })
    }

    setType(value: number) {
        this.setState({
            type: value
        })
    }

    setChannel(value: string[]) {
        this.setState({
            channel: value
        })
    }

    setIps(value: string) {
        this.setState({
            ips: value
        })
    }

    setSecret(value: string){
        this.setState({
            secret: value
        })
    }


    handleChangePass() {
        if (this.state.userId == null) {
            message.error("账号不能为空！");
            return;
        }

        if (this.state.pwd == null) {
            message.error("密码不能为空！");
            return;
        }

        let tm = Math.floor(new Date().valueOf() / 1000);
        let channel = "";
        if (this.state.channel.length > 0) {
            for (let i = 0; i < this.state.channel.length - 1; i++) {
                channel += this.state.channel[i] + ","
            }
            channel += this.state.channel[this.state.channel.length - 1]
        }

        NetUtil.Post(NetUtil.BaseUrl + "user/add",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(this.state.userId + channel + this.state.ips + this.state.nickname + this.state.pwd + this.state.secret + tm + this.state.type + StorageValue.userName + StorageValue.Token),
                add: this.state.userId,
                pwd: this.state.pwd,
                secret: this.state.secret,
                nickname: this.state.nickname,
                channel: channel,
                type: Number(this.state.type),
                ips: this.state.ips
            },
            (msg: any) => {
                let result = msg.result
                if (result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.error("添加用户成功");
                this.setState({
                    userId: "",
                    nickname: "",
                    pwd: "",
                    type: 3,
                    ips: "",
                })
                this.props.onSendApplySuccess();
            },
            (err: any) => {
                message.error(err);
                return;
            })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="添加用户"
                okText="添加"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => {
                    this.handleChangePass()
                }}
            >
                <Form style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "30px" }}>添加新用户</h1>
                    <Form.Item label='账号:'>
                        <Input
                            placeholder="玩家账号..."
                            // style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.userId}
                            onChange={(e) => this.setUserId(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='昵称:'>
                        <Input
                            placeholder="玩家显示昵称..."
                            // style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.nickname}
                            onChange={(e) => this.setNickName(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='密码:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.pwd}
                            onChange={(e) => this.setPwd(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='密钥:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="密钥，空时自动生成..."
                            prefix={<EyeOutlined />}
                            value={this.state.secret}
                            onChange={(e) => this.setSecret(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='ip白名单:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="ip白名单，空为不启用，多个英文逗号隔开"
                            prefix={<EyeOutlined />}
                            value={this.state.ips}
                            onChange={(e) => this.setIps(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='可用通道:' {...formItemStle}>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="选择充值通道，缺省为所有"
                            size={"large"}
                            onChange={(key: any) => { this.setChannel(key) }}
                        >
                            {
                                StorageValue.Channel.map((item: any) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='账号类型:' {...formItemStle}>
                        <Select
                            defaultValue='3'
                            size={"large"}
                            onSelect={(key: any) => { this.setType(key) }}
                        >
                            <Option value="3">使用者</Option>
                            <Option value="2">管理者</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}